.not-found-container section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15em 5em;
}

.not-found-container img {
  width: 12em;
}

.not-found-container p {
  text-align: center;
}

.not-found-container p:first-of-type {
  margin-top: 2em;
}

.not-found-container .not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-container .text-size-medium-3.sales.thankstitle._404 {
  font-size: 3em;
  line-height: 40px;
}

.not-found-container .link-33 {
  color: rgb(249, 81, 81);
}
