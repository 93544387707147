.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
}

.navbar1_dropdwn-toggle {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

.navbar1_menu-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-left: 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown-icon {
  margin-top: 0.75rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
}

.button-secondary {
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #f95151;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.button-secondary.button-small {
  padding: 0.5rem 1.25rem;
}

.navbar1_link {
  padding: 0.5rem 1rem;
  font-family: Oswald, sans-serif;
  color: #1e1d27;
  font-size: 13px;
}

.navbar1_link.w--current {
  color: transparent;
}

.navbar1_link.home {
  color: #fff;
  font-size: 16px;
}

.navbar1_link.home.donate {
  color: #79766a;
}

.navbar1_link.home.donate.w--current {
  color: #79766a;
}

.navbar1_dropdown-link {
  padding: 0.5rem 1rem;
}

.navbar1_dropdown-link.w--current {
  color: transparent;
}

.navbar1_dropdown-list.w--open {
  padding: 0.5rem;
  border: 1px solid #000;
  background-color: transparent;
}

.navbar1_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6e8e6;
}

.navbar1_container.home {
  background-color: rgba(230, 232, 230, 0);
}

.navbar1_component {
  height: 4.5rem;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 0px solid #000;
  background-color: #1e1d27;
}

.navbar1_component.faq {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #e6e8e6;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.navbar1_component.home {
  position: relative;
  display: block;
  margin-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(30, 29, 39, 0);
}

.navbar1_component.home.pricing {
  margin-top: 24px;
}

.navbar1_component.home.donate {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 0px;
  background-color: #e6e8e6;
}

.button {
  padding: 0.75rem 1.5rem;
  border: 0px solid transparent;
  border-radius: 10px;
  background-color: #f95151;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.button.button-small {
  padding: 0.5rem 1.25rem;
}

.button.button-small.donate {
  background-color: #004cff;
}

.navbar1_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar1_logo-link {
  padding-left: 0px;
}

.navbar1_menu-button {
  padding: 0rem;
}

.navbar1_button-wrapper {
  margin-left: 1rem;
}

.section-header2 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: -818px;
  padding-top: 24px;
  background-color: #1e1d27;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.text-link {
  color: #f95151;
}

.header2_component {
  display: -ms-grid;
  display: grid;
  padding-top: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1.25fr 1.25fr;
  grid-template-columns: 1.25fr 1.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.signup-form-wrapper {
  display: -ms-grid;
  display: grid;
  margin-bottom: 1rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  -ms-grid-columns: 1.25fr minmax(0px, 1.25fr);
  grid-template-columns: 1.25fr minmax(0px, 1.25fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-size-tiny {
  color: #bac3ba;
  font-size: 0.7rem;
}

.text-size-tiny.hero {
  color: #67647e;
}

.text-size-tiny._8mil {
  margin-top: 17px;
}

.text-size-tiny.footer {
  color: #919191;
}

.page-padding {
  margin-top: 0px;
  margin-bottom: 41px;
  padding-right: 5%;
  padding-left: 5%;
  border-style: none;
  border-width: 1px;
  border-color: #cfcfcf;
}

.page-padding.homeee {
  margin-top: 0px;
  margin-bottom: 0px;
  border-style: none;
}

.page-padding.center {
  text-align: center;
}

.page-padding.divvvv {
  margin-top: 75px;
}

.page-padding.sdkweb {
  margin-top: 48px;
}

.form {
  margin-top: 45px;
}

.margin-bottom {
  margin-top: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

.margin-bottom.margin-small {
  margin-top: -2.5rem;
}

.margin-bottom.margin-xsmall.sdkweb {
  text-align: left;
}

.margin-bottom.margin-xsmall.devshops {
  text-align: left;
}

.margin-bottom.margin-large {
  display: block;
  padding-bottom: 31px;
  text-align: center;
}

.margin-bottom.margin-large.sales {
  padding-right: 130px;
  padding-left: 63px;
  text-align: left;
}

.margin-bottom.margin-medium {
  margin-top: -2.2rem;
}

.margin-bottom.margin-xxlarge.faqq._2 {
  margin-top: 0rem;
  margin-bottom: 16px;
  padding-right: 0%;
  padding-left: 0%;
}

.margin-bottom.margin-xxlarge.home {
  margin-bottom: -78px;
  padding-right: 5%;
  padding-left: 5%;
}

.margin-bottom.margin-xxlarge.presss {
  margin-top: 0rem;
  margin-bottom: 32px;
  padding-right: 0%;
  padding-left: 0%;
}

.margin-bottom.margin-xsmall {
  margin-top: 0.4rem;
  padding-top: 0px;
  text-align: center;
}

.margin-bottom.margin-xxlarge {
  display: block;
  margin-top: 6.5rem;
  margin-bottom: 32px;
  padding-right: 0%;
  padding-left: 0%;
}

.text-size-medium {
  color: #646464;
  font-size: 0.9rem;
}

.text-size-medium.hero {
  color: #838195;
}

.text-size-medium.hero.bigger {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #b8b6cc;
  font-size: 1.2rem;
  line-height: 25px;
  font-weight: 400;
  white-space: normal;
}

.text-size-medium.hero.bigger.sdkweb {
  margin-top: 52px;
}

.text-size-medium.homee {
  margin-top: 58px;
}

.text-size-medium.homee.light {
  color: #cfcfcf;
}

.text-size-medium.homee.light.usecase {
  font-size: 1rem;
}

.text-size-medium.homee.light.usecase.small {
  color: #818181;
  font-size: 13px;
}

.text-size-medium.homeeee._2 {
  margin-top: 102px;
  font-size: 14px;
}

.text-size-medium.homeeee._2.light {
  color: #cfcfcf;
}

.text-size-medium.donate {
  margin-top: 76px;
  margin-bottom: 0px;
}

.text-size-medium.sdkweb {
  margin-top: 60px;
}

.text-size-medium.sdkweb.center {
  text-align: center;
}

.form-input {
  height: auto;
  min-height: 2.75rem;
  margin-bottom: 0px;
  padding: 0.5rem 0.75rem;
  border-style: solid;
  border-width: 1px;
  border-color: #3e3c48;
  border-radius: 20px;
  background-color: rgba(96, 91, 120, 0);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
}

.form-input:focus {
  border-color: #000;
}

.form-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input.hero {
  border-style: none;
  border-color: #3e3c48;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.47);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #000;
  font-weight: 600;
}

.form-input.connecting {
  border-color: #b8b8b8;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
  color: #000;
}

.form-input.connecting.subscribe {
  background-color: #fff;
  color: #000;
}

.header2_form {
  max-width: 30rem;
  margin-bottom: 0px;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.padding-vertical {
  padding-right: 0rem;
  padding-left: 0rem;
}

.padding-vertical.padding-xxlarge {
  display: block;
}

.padding-vertical.padding-xhuge {
  padding-top: 24px;
}

.button-tertiary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #f95151;
  line-height: 1;
  text-decoration: none;
}

.button-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-top: 30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-tertiary_icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: #f95151;
}

.section-layout89 {
  overflow: hidden;
  margin-top: 76px;
  margin-bottom: 121px;
}

.section-layout89.usecasessss {
  margin-bottom: 44px;
}

.section-layout89.sdkweb {
  overflow: visible;
  margin-top: 0px;
}

.layout89_component {
  display: -ms-grid;
  display: grid;
  padding-bottom: 36px;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.layout89_image-wrapper {
  margin-top: 29px;
  margin-bottom: 29px;
}

.text-weight-semibold {
  display: inline-block;
  font-family: Oswald, sans-serif;
  color: #454546;
  font-size: 11px;
  font-weight: 700;
  text-align: left;
}

.text-weight-semibold.dark.home {
  margin-top: -50px;
}

.text-weight-semibold.dark.home.centered {
  text-align: left;
}

.text-weight-semibold.cases {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}

.text-weight-semibold.connecting {
  text-align: center;
}

.text-weight-semibold.homee {
  margin-bottom: 28px;
}

.text-weight-semibold.white {
  color: #fff;
}

.text-weight-semibold.dark {
  margin-top: 58px;
  color: #fff;
}

.button-wrapper {
  margin-top: 1rem;
  margin-right: 1rem;
}

.cta26_form {
  max-width: 30rem;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.section-cta26 {
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 689px;
  padding-bottom: 243px;
}

.section-cta26.midsect {
  margin-top: -86px;
}

.text-align-center {
  text-align: center;
}

.error-message {
  margin-top: 1.5rem;
  padding: 0.875rem 1rem;
}

.error-text {
  color: #e23939;
}

.success-text {
  font-weight: 600;
}

.container-small {
  width: 100%;
  max-width: 87.8rem;
  margin-right: auto;
  margin-left: auto;
}

.success-message {
  padding: 1.5rem;
  background-color: transparent;
}

.layout219_lightbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout219_lightbox-image {
  -o-object-fit: cover;
  object-fit: cover;
}

.layout219_tabs-menu {
  color: #f95151;
}

.video-overlay-layer {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.section-layout219 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 27px;
  padding-bottom: 37px;
  background-color: #1e1d27;
}

.section-layout219.caseshero.usecasess {
  padding-top: 0px;
  padding-bottom: 143px;
}

.lightbox-play-icon {
  position: absolute;
  z-index: 2;
  width: 64px;
  height: 64px;
  color: #fff;
}

.heading-medium {
  font-family: Oswald, sans-serif;
  color: #f95151;
  font-size: 0.8rem;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
}

.heading-medium.feat {
  color: #fff;
  font-size: 1rem;
}

.heading-medium.feat.sdkweb {
  text-align: left;
}

.layout219_tabs-link {
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0px 0px 0px 2rem;
  border-left: 2px solid transparent;
  background-color: transparent;
}

.layout219_tabs-link.w--current {
  border-left-color: #f95151;
  background-color: transparent;
  opacity: 1;
}

.layout219_component {
  display: -ms-grid;
  display: grid;
  margin-top: 59px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #f95151;
}

.section-layout210 {
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.section-layout210.lastsect {
  margin-top: -160px;
  margin-bottom: -64px;
}

.layout210_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 2fr 2.5fr;
  grid-template-columns: 2fr 2.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.layout210_component.home {
  padding-right: 150px;
  padding-left: 150px;
  grid-column-gap: 1rem;
  -ms-grid-columns: 2fr 2fr;
  grid-template-columns: 2fr 2fr;
}

.layout210_component.usecasegrid {
  margin-bottom: 80px;
}

.layout210_list {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.layout210_list.home {
  margin-top: 54px;
}

.layout210_list.home.light {
  display: block;
  color: #cfcfcf;
}

.section-layout192 {
  overflow: hidden;
  margin-top: 15px;
}

.layout192_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-layout35 {
  margin-top: 40px;
  margin-bottom: 0px;
}

.text-weight-semibold-2 {
  font-family: Oswald, sans-serif;
  color: #454546;
  font-size: 11px;
  font-weight: 700;
}

.text-weight-semibold-2.homee {
  margin-bottom: 48px;
}

.max-width-medium {
  width: 100%;
  max-width: 35rem;
}

.footer2_logo-link {
  padding-left: 0px;
}

.footer2_legal-wrapper {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0rem;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 10px;
  white-space: normal;
}

.footer2_social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
}

.footer2_form {
  margin-bottom: 0rem;
}

.line-divider {
  width: 100%;
  height: 1px;
  background-color: transparent;
}

.footer2_link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #f95151;
  font-size: 0.875rem;
  text-decoration: none;
}

.footer2_credit-text {
  color: #bac3ba;
  font-size: 0.7rem;
}

.social-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #f95151;
}

.text-size-small {
  font-size: 0.8rem;
}

.text-size-small.footer {
  margin-top: 28px;
  color: #919191;
}

.footer2_bottom-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 46px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer2_column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 100%;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer2_form-wrapper {
  display: -ms-grid;
  display: grid;
  margin-bottom: 1rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer2_column-layout {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 3vw;
  grid-row-gap: 1rem;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.padding-bottom {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
}

.padding-top {
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.footer2_top-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 8vw;
  grid-row-gap: 1rem;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer2_component {
  overflow: hidden;
  margin-top: 139px;
  margin-bottom: 59px;
  padding-top: 66px;
  padding-bottom: 49px;
  padding-left: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1e1d27), to(#1e1d27));
  background-image: linear-gradient(180deg, #1e1d27, #1e1d27);
}

.footer2_component.devshop {
  margin-top: 0px;
}

.footer2_socials-wrapper {
  display: -ms-grid;
  display: grid;
  justify-items: start;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0rem;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  white-space: normal;
}

.footer2_right-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer2_bottom-link {
  color: #f95151;
  font-size: 0.7rem;
}

.footer2_column-heading {
  margin-bottom: 0.5rem;
  font-family: Oswald, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

.footer2_column-heading.footer {
  color: #fff;
}

.page-padding-2 {
  margin-top: -104px;
  padding-top: 0px;
  padding-right: 5%;
  padding-left: 5%;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.page-padding-2.priciing {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.page-padding-2.priciing.pricingdiv {
  display: block;
  margin-top: -18px;
  margin-bottom: 0px;
  padding-right: 5%;
  padding-left: 5%;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.page-padding-2.faq.divfaq {
  margin-top: 10px;
}

.page-padding-2.pricing {
  margin-top: 3px;
}

.page-padding-2.pricing.procingfaqdiv {
  margin-top: 1010px;
  padding-top: 0px;
}

.page-padding-2.sales.saless {
  margin-top: -288px;
  padding-top: 142px;
  padding-bottom: 25px;
  background-color: #1e1d27;
}

.page-padding-2.donate {
  margin-top: -86px;
}

.layout194_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4px;
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.body {
  background-color: #1e1d27;
}

.heading {
  margin-top: 75px;
  margin-bottom: -20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  font-size: 50px;
  line-height: 55px;
}

.heading.devshop {
  margin-bottom: 61px;
}

.navbar1_component-2 {
  height: 4.5rem;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
  background-color: transparent;
}

.button-2 {
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  background-color: transparent;
  color: transparent;
  text-align: center;
}

.button-2.button-small {
  padding: 0.5rem 1.25rem;
}

.button-2.max-width-full {
  display: block;
  margin-top: auto;
  margin-right: 0px;
  border-style: none;
  border-radius: 12px;
  background-color: #03f;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.31);
  color: #fff;
}

.button-2.max-width-full.red {
  margin-right: 0px;
  border-style: none;
  background-color: #f95151;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.29);
}

.button-2.max-width-full.red.home {
  margin-top: auto;
  margin-right: 0px;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.47);
}

.button-2.max-width-full.red.home.dashboard {
  border: 1px solid #f95151;
  background-color: rgba(249, 81, 81, 0);
}

.button-2.max-width-full.red.home.dashboard.donate {
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.18);
  color: #f95151;
}

.button-2.max-width-full.red.home.splashpage {
  margin-top: 47px;
  margin-right: 0px;
}

.button-2.max-width-full.red.home.splashpage.blue {
  border: 2px solid #b8b6cc;
  background-color: #1e1d27;
}

.button-2.max-width-full.red.pholosophy {
  margin-top: 41px;
  margin-right: 500px;
  margin-left: 500px;
}

.button-2.max-width-full.red.pholosophy.sdkweb.centered {
  text-align: center;
}

.faq2_component {
  border-bottom: 0px solid #000;
}

.section-faq2 {
  margin-top: -9px;
  margin-bottom: 43px;
}

.section-faq2.dibvv {
  margin-top: 87px;
}

.faq2_question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  font-family: Oswald, sans-serif;
  color: #000;
  cursor: pointer;
}

.max-width-large {
  display: inline;
  width: 100%;
  max-width: 48rem;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.max-width-large.align-center {
  display: inline;
  padding-bottom: 34px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: left;
}

.margin-top {
  margin-right: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
}

.margin-top.margin-medium {
  margin-top: 26px;
}

.faq2_answer {
  overflow: hidden;
  padding-top: 0px;
}

.margin-top-2.margin-xxlarge {
  margin-top: 74px;
}

.text-size-medium-2 {
  color: #1e1d27;
  font-size: 1.125rem;
}

.faq2_icon {
  width: 2rem;
  margin-left: 1.5rem;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.faq2_accordion {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.paragraph {
  margin-bottom: 0px;
  color: #838195;
  font-size: 13px;
  text-align: center;
}

.paragraph._4 {
  color: #cecece;
  font-size: 14px;
  text-align: left;
}

.paragraph-2 {
  margin-bottom: 41px;
  padding-bottom: 0px;
  color: #cecece;
  font-size: 14px;
}

.paragraph-2.bigger {
  font-size: 24px;
}

.paragraph-3 {
  color: #cecece;
  font-size: 14px;
}

.paragraph-3.cases {
  margin: 0px 5px 27px;
  color: #595f59;
  text-align: center;
}

.paragraph-3.cases.light {
  color: #cfcfcf;
}

.heading-2 {
  padding-right: 442px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
  line-height: 40px;
}

.heading-2.cases {
  padding-right: 0px;
}

.heading-2.donate {
  padding-right: 0px;
}

.heading-2.centered {
  padding-right: 0px;
}

.heading-2.centered.bigger {
  margin-bottom: 30px;
  font-size: 60px;
  line-height: 60px;
}

.heading-3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 74px;
  line-height: 75px;
  mix-blend-mode: normal;
}

.heading-4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
  line-height: 40px;
}

.heading-4.homee {
  margin-bottom: -39px;
}

.heading-5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
  line-height: 40px;
}

.heading-6 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
  line-height: 40px;
}

.heading-6.start {
  font-size: 50px;
  line-height: 50px;
}

.text-block {
  color: #f95151;
  font-weight: 700;
}

.text-block-2 {
  color: #f95151;
  font-weight: 700;
}

.text-span {
  color: #f95151;
}

.form-2 {
  margin-top: 18px;
}

.image {
  position: absolute;
  left: 952px;
  top: -13px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: inline-block;
  float: none;
}

.image-2.imgpay {
  margin-top: 0px;
}

.image-2.imgmusic {
  margin-top: 0px;
}

.image-2.imgsocial {
  margin-top: 0px;
}

.image-3 {
  position: absolute;
  left: 128px;
  top: 1577px;
}

.image-4 {
  position: absolute;
  left: -38px;
  top: 552.594px;
  right: -399px;
  bottom: 129.594px;
  z-index: 0;
  overflow: hidden;
  max-width: 36%;
}

.image-5 {
  position: absolute;
  left: -128px;
  top: -99.406px;
  right: 378px;
  bottom: -77.406px;
  z-index: 5;
  width: 780px;
}

.image-6 {
  position: absolute;
  left: 1233.5px;
  top: 497.594px;
  right: 1279.5px;
  bottom: 603.594px;
  z-index: 2;
  width: 930px;
  max-width: 40%;
}

.image-7 {
  position: absolute;
  left: 270.5px;
  top: 915.594px;
  right: 103.5px;
  bottom: 892.594px;
  z-index: -1;
}

.body-2 {
  background-color: #e6e8e6;
}

.heading-7 {
  margin-top: -20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
}

.heading-7.pricing._2 {
  margin-top: 11px;
}

.heading-7.faq {
  margin-top: 19px;
}

.heading-7.sales.terms {
  text-align: left;
}

.heading-7.donate {
  margin-top: 11px;
  margin-bottom: 27px;
}

.heading-7.sales {
  margin-top: 22px;
  margin-bottom: -74px;
  padding-right: 399px;
  line-height: 40px;
}

.image-8 {
  position: relative;
  left: -81px;
  top: auto;
  right: 0%;
  bottom: auto;
  display: block;
  max-width: 150%;
  margin-top: -44px;
  margin-bottom: 47px;
}

.image-8.blue {
  left: -79px;
  top: -820px;
  right: -6%;
  z-index: 0;
  -o-object-fit: fill;
  object-fit: fill;
}

.columns {
  text-align: center;
}

.image-9 {
  position: absolute;
  left: auto;
  top: auto;
  width: 900px;
  max-width: 70%;
}

.paragraph-4 {
  color: #79766a;
}

.paragraph-5 {
  color: #79766a;
}

.paragraph-6 {
  margin-top: -42px;
  margin-bottom: 26px;
  padding-top: 0px;
  color: #79766a;
}

.paragraph-7 {
  color: #79766a;
}

.image-10 {
  position: relative;
  z-index: 1;
  max-width: 114%;
  margin-right: -25px;
  margin-left: -99px;
  padding-bottom: 79px;
}

.div {
  background-color: #1e1d27;
}

.div.homediv {
  background-color: #e6e8e6;
}

.heading-xsmall {
  border: 1px none #838195;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 700;
}

.icon-1x1-xsmall {
  width: 1.5rem;
  height: 1.5rem;
}

.pricing12_plan {
  padding: 2rem;
  border: 2px solid #03f;
  border-radius: 13px;
  background-color: rgba(30, 29, 39, 0.56);
}

.pricing12_plan.plan1 {
  border-color: #f95151;
}

.margin-vertical {
  margin-right: 0rem;
  margin-left: 0rem;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-right: auto;
  margin-left: auto;
}

.text-size-medium-3 {
  color: #fff;
  font-size: 1.125rem;
}

.text-size-medium-3.pricing {
  margin-top: 32px;
  margin-right: 0px;
  margin-bottom: -23px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  font-weight: 400;
}

.text-size-medium-3.sales {
  margin-bottom: 0px;
  color: #888;
}

.text-size-medium-3.sales.terms {
  margin-top: 47px;
  color: #686868;
  font-size: 0.8rem;
  text-align: justify;
}

.text-size-medium-3.sales.thanks {
  font-size: 1rem;
  font-weight: 400;
}

.text-size-medium-3.sales.thankstitle {
  margin-bottom: 32px;
  color: #505050;
  font-size: 1.3rem;
}

.pricing12_feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pricing12_icon-wrapper {
  margin-right: 1rem;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.pricing12_component {
  margin-top: 47px;
  margin-bottom: 47px;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-pricing12 {
  margin-top: 14px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(30, 29, 39, 0);
}

.section-pricing12.divv {
  margin-top: 59px;
}

.pricing12_divider {
  width: 100%;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: #403e4e;
  border-radius: 0px;
  background-color: transparent;
}

.text-weight-semibold-3 {
  font-weight: 600;
}

.heading-medium-2 {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 700;
}

.pricing12_feature-list {
  display: -ms-grid;
  display: grid;
  padding-top: 0rem;
  padding-bottom: 0rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-xxlarge {
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: 700;
}

.contact12_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.contact12_form-block {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.form-field-2col {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.icon-1x1-small {
  width: 2rem;
  height: 2rem;
}

.contact12_contact-list {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 2.5rem;
  -ms-grid-columns: 0.25fr 7.75fr;
  grid-template-columns: 0.25fr 7.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-radio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 1.125rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-radio-2col {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0.875rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-tertiary_icon-2 {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: #f95151;
}

.contact12_form {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.form-checkbox-label {
  margin-bottom: 0px;
}

.form-checkbox-label.text-size-small {
  color: #949494;
}

.field-label {
  margin-bottom: 0.5rem;
  color: #595f59;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
}

.field-label.small {
  font-size: 12px;
}

.field-label.small.terms {
  margin-top: 10px;
}

.form-field-wrapper {
  position: relative;
}

.form-radio-label {
  margin-bottom: 0px;
}

.section-contact12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 194px;
  padding-top: 146px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-contact12.form.divvv {
  padding-top: 223px;
}

.section-contact12.terms {
  padding-top: 131px;
}

.section-contact12.form {
  display: block;
  margin-bottom: 63px;
  padding-top: 284px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.section-contact12.form.devshop {
  margin-bottom: 0px;
}

.form-input-2 {
  height: auto;
  min-height: 2.75rem;
  margin-bottom: 0px;
  padding: 0.5rem 0.75rem;
  border-style: solid;
  border-width: 1px;
  border-color: #c9c9c9;
  border-radius: 20px;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

.form-input-2:focus {
  border-color: #000;
}

.form-input-2::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-2:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-2::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-2::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-2.select {
  padding-right: 1.65rem;
  border-radius: 10px;
  background-color: #fff;
  background-image: none;
  line-height: 1.6;
}

.form-input-2.text-area {
  display: block;
  overflow: auto;
  height: auto;
  min-height: 11.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.form-radio-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  margin-top: 0px;
  margin-right: 0.5rem;
  margin-left: -1.125rem;
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
}

.form-radio-icon.w--redirected-checked {
  border-width: 6px;
  border-color: #000;
  background-color: #fff;
  background-image: none;
}

.form-radio-icon.w--redirected-focus {
  margin-top: 0px;
  border-color: #000;
  box-shadow: none;
}

.form-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 1.25rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-checkbox-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  margin-top: 0px;
  margin-right: 0.5rem;
  margin-left: -1.25rem;
  border: 1px solid #c9c9c9;
  border-radius: 0px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.form-checkbox-icon.w--redirected-checked {
  border-width: 1px;
  border-color: #000;
  background-color: #000;
  background-size: 16px 16px;
  box-shadow: none;
}

.form-checkbox-icon.w--redirected-focus {
  border-color: #000;
  box-shadow: none;
}

.text-style-link {
  text-decoration: underline;
}

.columns-2 {
  border-style: solid;
  border-width: 1px;
  border-color: #c5c5c5;
  border-radius: 11px;
  outline-color: #333;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 1px;
}

.container {
  display: block;
  padding-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #d6d6d6;
  border-radius: 14px;
}

.section {
  border-style: solid;
  border-width: 1px;
  border-color: #c2c2c2;
  border-radius: 12px;
}

.grid {
  grid-row-gap: 15px;
  -ms-grid-columns: 1fr 3.5fr;
  grid-template-columns: 1fr 3.5fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.layout31_item-list {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 2rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout31_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.icon-1x1-medium {
  width: 3rem;
  height: 3rem;
}

.heading-small {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 700;
}

.heading-small.test {
  margin-top: 19px;
  margin-bottom: 34px;
}

.section-layout31 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.div-block {
  background-color: rgba(30, 29, 39, 0);
}

.text-block-3 {
  color: #838195;
}

.text-block-4 {
  color: #838195;
}

.text-block-5 {
  color: #838195;
}

.text-block-6 {
  color: #838195;
}

.text-block-7 {
  color: #838195;
}

.text-block-8 {
  color: #838195;
}

.text-block-9 {
  color: #838195;
}

.text-block-10 {
  color: #838195;
}

.text-block-11 {
  color: #838195;
}

.text-block-12 {
  color: #f95151;
}

.text-block-13 {
  color: #f95151;
}

.text-block-14 {
  color: #838195;
}

.background-video {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  overflow: visible;
  margin-bottom: 457px;
  padding-bottom: 0px;
  -o-object-fit: cover;
  object-fit: cover;
}

.div-block-2 {
  margin-bottom: 47px;
}

.div-block-2.divvv {
  padding-top: 71px;
}

.background-video-2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.background-video-3 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  overflow: visible;
  height: 969px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  float: none;
  clear: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.image-12 {
  position: absolute;
  left: 40%;
  top: 13%;
  right: 0%;
  bottom: auto;
  z-index: 0;
  display: inline-block;
  max-width: 18%;
  -o-object-fit: fill;
  object-fit: fill;
}

.link {
  color: #f95151;
}

.link-2 {
  color: #f95151;
}

.link-3 {
  color: #f95151;
}

.link-4 {
  color: #f95151;
}

.link-5 {
  color: #f95151;
}

.link-6 {
  color: #f95151;
}

.link-7 {
  color: #f95151;
}

.link-8 {
  color: #f95151;
}

.link-9 {
  color: #f95151;
}

.link-10 {
  color: #f95151;
}

.link-11 {
  color: #f95151;
}

.link-12 {
  color: #f95151;
}

.link-13 {
  color: #f95151;
}

.background-video-4 {
  position: relative;
  overflow: visible;
  height: 700px;
  margin-top: 30px;
  margin-bottom: 34px;
}

.faq_answernew {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 22px;
  color: #646464;
}

.link-14 {
  color: #f95151;
}

.bold-text {
  color: #f95151;
}

.text-span-2 {
  color: #f95151;
}

.text-span-3 {
  color: #f95151;
}

.bold-text-2 {
  color: #f95151;
  font-weight: 400;
}

.link-15 {
  color: #f95151;
}

.text-span-4 {
  color: #f95151;
}

.bold-text-3 {
  color: #f95151;
}

.link-16 {
  color: #f95151;
}

.image-13 {
  border-style: none;
  border-width: 2px;
  border-color: #464453;
  border-radius: 20px;
}

.image-14 {
  position: relative;
  left: 52px;
  top: -65px;
  max-width: 80%;
}

.link-17 {
  color: #f95151;
}

.image-15 {
  margin-bottom: 23px;
}

.link-18 {
  color: #f95151;
}

.link-19 {
  color: #f95151;
}

.columns-3 {
  display: block;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-2 {
  margin-right: 0px;
  margin-left: 0%;
  padding-right: 5%;
  padding-left: 5%;
  grid-row-gap: 16px;
  grid-template-areas: "Area . . Area-2";
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.button-tertiary-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  text-decoration: none;
}

.button-secondary-2 {
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  background-color: transparent;
  color: transparent;
  text-align: center;
}

.page-padding-3 {
  overflow: visible;
  padding-right: 5%;
  padding-left: 5%;
}

.layout128_component {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.headfootsales {
  margin-top: 52px;
  color: #f95151;
}

.paragraph-8 {
  color: #595f59;
}

.text-block-15 {
  color: #f95151;
}

.text-block-16 {
  color: #f95151;
}

.select-field-2 {
  height: 45px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  line-height: 31px;
}

.link-20 {
  color: #f95151;
}

.link-21 {
  color: #f95151;
}

.button-3 {
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  background-color: transparent;
  color: transparent;
  text-align: center;
}

.button-3.max-width-full {
  display: block;
  margin-top: auto;
  margin-right: 0px;
  border-style: none;
  border-radius: 12px;
  background-color: #03f;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.31);
  color: #fff;
}

.button-3.max-width-full.red {
  margin-right: 549px;
  border-style: none;
  background-color: #f95151;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.29);
}

.button-3.max-width-full.red.home {
  margin-top: auto;
  margin-right: 0px;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.47);
  font-weight: 400;
}

.button-3.max-width-full.red.home.devshops {
  margin-top: 57px;
  margin-right: 323px;
}

.line-divider-2 {
  width: 100%;
  height: 1px;
  background-color: transparent;
}

.link-22 {
  color: #f95151;
}

.link-23 {
  color: #f95151;
}

.grid-3 {
  margin-top: 56px;
  padding: 15px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: minmax(72px, 0.25fr) minmax(81px, 1fr) minmax(358px, 0.25fr);
  grid-template-columns: minmax(72px, 0.25fr) minmax(81px, 1fr) minmax(358px, 0.25fr);
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-style: solid;
  border-width: 1px;
  border-color: #b1b1b1;
  border-radius: 14px;
  outline-color: #333;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  mix-blend-mode: normal;
}

.text-block-17 {
  text-align: left;
}

.bold-text-4 {
  color: #686868;
}

.link-24 {
  color: #f95151;
}

.link-25 {
  color: #f95151;
}

.link-26 {
  color: #f95151;
}

.link-27 {
  color: #f95151;
}

.div-block-3 {
  margin-top: 827px;
}

.image-16 {
  max-width: 40%;
}

.column {
  display: inline-block;
}

.column-2 {
  display: inline-block;
}

.column-3 {
  display: inline-block;
}

.column-4 {
  display: inline-block;
}

.featured-image {
  -o-object-fit: cover;
  object-fit: cover;
}

.text-block-18 {
  text-align: center;
}

.collection-item {
  display: block;
  text-align: center;
}

.image-17 {
  display: inline-block;
  margin-top: 40px;
}

.image-18 {
  text-align: left;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 94px;
  padding: 0px 10% 79px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid #3f3d50;
  outline-color: #555;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
}

.usecase {
  font-size: 16px;
}

.collection-list-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.text-span-5 {
  color: #f95151;
}

.bold-text-5 {
  text-decoration: underline;
}

.div-block-5 {
  padding-right: 20%;
  padding-left: 0%;
}

.link-28 {
  color: #ff4040;
}

.link-29 {
  color: #f95151;
}

.link-30 {
  color: #f95151;
}

.collection-list-wrapper-2 {
  overflow: hidden;
}

.columns-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.container-2 {
  display: block;
}

.navbar1_menu-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar1_link-2 {
  padding: 0.5rem 1rem;
  font-family: Oswald, sans-serif;
  color: #1e1d27;
  font-size: 13px;
}

.navbar1_link-2.w--current {
  color: transparent;
}

.navbar1_link-2.home {
  color: #fff;
  font-size: 16px;
}

.navbar1_link-2.home.donate {
  color: #79766a;
  text-transform: uppercase;
}

.navbar1_link-2.home.donate.w--current {
  color: #79766a;
}

.image-20 {
  position: relative;
  left: -81px;
  top: auto;
  right: 0%;
  bottom: auto;
  display: block;
  max-width: 150%;
  margin-top: -44px;
  margin-bottom: 74px;
}

.image-21 {
  position: relative;
  z-index: 1;
  max-width: 80%;
  margin-right: -25px;
  margin-left: 43px;
  padding-bottom: 83px;
}

.text-size-medium-4 {
  color: #919191;
  font-size: 0.9rem;
}

.text-size-medium-4.hero {
  color: #838195;
}

.text-size-medium-4.hero.bigger {
  margin-top: 55px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #686868;
  font-size: 1.2rem;
  line-height: 25px;
  font-weight: 400;
  white-space: normal;
}

.text-size-medium-4.homee {
  margin-top: 58px;
}

.text-size-medium-4.homee.work {
  margin-bottom: 37px;
  color: #646464;
}

.text-size-medium-4.homee.uses {
  margin-top: 0px;
  color: #646464;
}

.link-block {
  margin-top: 23px;
  padding-right: 22px;
}

.image-22 {
  position: relative;
  left: -81px;
  top: auto;
  right: 0%;
  bottom: auto;
  display: block;
  max-width: 150%;
  margin-top: -44px;
  margin-bottom: 47px;
}

.image-22.blue {
  left: -111px;
  top: -981px;
  right: -6%;
  z-index: 1;
  overflow: visible;
  max-width: 200%;
  margin-top: -219px;
  margin-left: -154px;
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-8 {
  margin-bottom: -20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #333238;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: -1px;
}

.page-padding-4 {
  margin-top: -93px;
  margin-bottom: 0px;
  padding-right: 5%;
  padding-left: 5%;
  border-style: none;
  border-width: 1px;
  border-color: #cfcfcf;
}

.page-padding-4.first {
  margin-top: -83px;
  margin-bottom: -1200px;
}

.layout89_component-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-9 {
  padding-right: 664px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
  line-height: 40px;
}

.heading-9.donate {
  padding-right: 0px;
}

.heading-9.cases {
  margin-top: 0px;
  padding-right: 0px;
  font-size: 40px;
}

.container-large-2 {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 33px;
  padding-bottom: 61px;
}

.columns-6 {
  text-align: center;
}

.columns-6.devshops {
  margin-top: -34px;
}

.layout89_content-left-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-padding-5 {
  margin-top: 0px;
  margin-bottom: 41px;
  padding-right: 5%;
  padding-left: 5%;
  border-style: none;
  border-width: 1px;
  border-color: #cfcfcf;
}

.page-padding-5.homeee {
  overflow: visible;
  margin-top: 89px;
  border-style: none;
  background-image: url('../images/dotted.png');
  background-position: 50% 70%;
  background-repeat: no-repeat;
}

.page-padding-5.devshop {
  margin-bottom: 0px;
}

.text-weight-semibold-4 {
  display: inline-block;
  font-family: Oswald, sans-serif;
  color: #454546;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}

.text-weight-semibold-4.cases {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}

.text-weight-semibold-4.homee {
  margin-bottom: 28px;
}

.button-row-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-layout210-2 {
  margin-top: -102px;
  margin-bottom: 82px;
}

.layout210_component-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.testimonial7_component {
  height: auto;
  padding-bottom: 4rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.testimonial7_slide-nav {
  height: 1.75rem;
  font-size: 0.5rem;
}

.testimonial4_rating-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #f95151;
}

.testimonial4_rating-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.testimonial7_client-image-wrapper {
  margin-bottom: 1rem;
}

.testimonial7_arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1rem;
  height: 3.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 100%;
  background-color: transparent;
}

.testimonial7_arrow.hide-mobile-landscape {
  border-style: none;
}

.testimonial7_client-image {
  width: 4rem;
  height: 4rem;
  min-height: 4rem;
  min-width: 4rem;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container-large-3 {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  padding-bottom: 44px;
  padding-left: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #c9c9c9;
  border-radius: 18px;
}

.testimonial7_slide {
  padding-right: 4rem;
  padding-left: 4rem;
}

.section-testimonial7-2 {
  overflow: visible;
  padding-top: 71px;
  background-color: #dee0de;
}

.testimonial7_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 48rem;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading-xsmall-2 {
  margin-bottom: 40px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #595f59;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 400;
}

.testimonial11_client {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.paragraph-9 {
  margin-top: -12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #595f59;
}

.testimonial11_client-image-wrapper {
  margin-right: 1.25rem;
}

.testimonial11_slide-nav {
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  height: 1.75rem;
  font-size: 0.5rem;
}

.testimonial11_component {
  overflow: hidden;
  height: auto;
  padding-bottom: 6rem;
  background-color: transparent;
}

.max-width-large-2 {
  width: 100%;
  max-width: 48rem;
}

.italic-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-style: normal;
  font-weight: 700;
}

.testimonial11_divider {
  width: 1px;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: transparent;
}

.testimonial11_mask {
  overflow: visible;
  width: 50%;
}

.testimonial11_client-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.section-testimonial11 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 48px;
  padding-bottom: 1px;
  background-color: #dee0de;
}

.testimonial11_arrow {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3rem;
  height: 3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none transparent;
  border-radius: 100%;
  background-color: transparent;
}

.testimonial11_arrow.left {
  right: 4rem;
  border-style: none;
  background-color: transparent;
}

.image-24 {
  position: absolute;
  left: 1057px;
  top: 341px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  display: inline-block;
  overflow: visible;
  margin-top: 2193px;
  margin-left: 367px;
  float: none;
}

.image-24._2 {
  left: -219px;
  top: 719px;
  overflow: visible;
  margin-top: 1794px;
  margin-left: -359px;
}

.testimonial11_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.testimonial11_slide {
  padding-right: 4rem;
}

.heading-10 {
  margin-top: -16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #f95151;
  font-size: 40px;
}

.div-block-6 {
  padding: 67px 30px 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #cecece;
  border-radius: 20px;
}

.div-block-7 {
  padding-top: 8px;
  padding-bottom: 103px;
  background-color: #252525;
}

.footer2_form-wrapper-2 {
  display: -ms-grid;
  display: grid;
  margin-bottom: 1rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer2_component-2 {
  margin-top: 0px;
  margin-bottom: 59px;
  padding-top: 66px;
  padding-bottom: 5px;
  padding-left: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#252525), to(#252525));
  background-image: linear-gradient(180deg, #252525, #252525);
}

.footer2_credit-text-2 {
  color: #919191;
  font-size: 0.7rem;
}

.section-faq2-2 {
  margin-top: -9px;
  margin-bottom: 0px;
}

.container-large-4 {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.layout89_component-3 {
  display: -ms-grid;
  display: grid;
  padding-bottom: 36px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-layout31-2 {
  margin-bottom: 60px;
}

.layout31_component-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.layout31_item-list-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 2rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-25 {
  display: inline-block;
  max-width: 70%;
}

.sdkweb {
  text-align: left;
}

.body-3 {
  background-color: #e6e8e6;
}

.body-4 {
  background-color: #e6e8e6;
}

.link-31 {
  color: #f95151;
}

.link-32 {
  color: #f95151;
}

.column-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.column-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.column-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.container-large-5 {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 33px;
  padding-bottom: 61px;
}

.layout89_component-4 {
  display: -ms-grid;
  display: grid;
  padding-bottom: 36px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  -ms-grid-columns: 2.5fr 2fr;
  grid-template-columns: 2.5fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-8 {
  margin-top: 28px;
  margin-bottom: -80px;
  border-top: 1px solid #b9b9b9;
}

.section-layout210-3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-layout210-3.devshop {
  margin-top: -76px;
}

.div-block-9 {
  border: 1px none #000;
}

.div-block-9.devshop {
  padding-top: 125px;
}

.footer2_bottom-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 46px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-weight-semibold-5 {
  display: inline-block;
  margin-bottom: 35px;
  font-family: Oswald, sans-serif;
  color: #454546;
  font-size: 11px;
  font-weight: 700;
  text-align: left;
}

.text-weight-semibold-5.dark {
  margin-top: 58px;
  color: #fff;
}

.text-weight-semibold-5.dark.home {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.header2_content-2 {
  margin-top: -116px;
}

.image-26 {
  position: relative;
  z-index: 1;
  max-width: 80%;
  margin-right: -25px;
  margin-left: 47px;
  padding-bottom: 79px;
}

.contact12_form-block-2 {
  margin-top: 62px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.page-padding-6 {
  margin-top: 75px;
  margin-bottom: 45px;
  padding-right: 5%;
  padding-left: 5%;
  border-style: none;
  border-width: 1px;
  border-color: #cfcfcf;
  background-color: #e6e8e6;
}

.page-padding-6.homeee {
  margin-top: -115px;
  border-style: none;
}

.section-layout210-4 {
  margin-top: -1px;
  margin-bottom: 0px;
  padding-top: 65px;
}

.section-layout210-4.devshop {
  margin-top: 0px;
  padding-top: 0px;
}

.section-layout210-4.s2 {
  padding-top: 0px;
}

.section-layout210-4.s2.devshop {
  margin-top: -75px;
}

.success-message-2 {
  padding: 1.5rem;
  background-color: transparent;
}

.bold-text-6.devshops {
  font-size: 40px;
  line-height: 45px;
}

.section-header2-2 {
  margin-top: -55px;
  margin-bottom: -818px;
  padding-top: 0px;
  background-color: rgba(30, 29, 39, 0);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.section-header2-2.devshops {
  margin-top: -33px;
  margin-bottom: -894px;
}

.footer2_component-3 {
  margin-top: -70px;
  margin-bottom: 59px;
  padding-top: 2px;
  padding-bottom: 10px;
  padding-left: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1e1d27), to(#1e1d27));
  background-image: linear-gradient(180deg, #1e1d27, #1e1d27);
}

.bold-text-7 {
  line-height: 45px;
}

.field-label-2 {
  margin-bottom: 0.5rem;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
}

.field-label-2.small {
  font-size: 12px;
}

.field-label-2.small.terms {
  margin-top: 10px;
}

.field-label-2.small.terms.lp {
  color: grey;
}

.field-label-2.small.terms.lp.devshops {
  text-align: left;
}

.section-layout89-2 {
  margin-top: 100px;
  margin-bottom: 123px;
  padding-top: 207px;
}

.section-layout89-2.devshop {
  margin-top: 0px;
  padding-top: 62px;
}

.bold-text-8 {
  color: #f95151;
  font-size: 60px;
  letter-spacing: -1px;
}

.text-size-medium-5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #595f59;
  font-size: 1.2rem;
}

.text-size-medium-5.homee {
  margin-top: 58px;
}

.text-size-medium-5.homee.devshop {
  font-size: 2rem;
  line-height: 40px;
}

.text-size-medium-5.hero {
  color: #838195;
}

.text-size-medium-5.hero.bigger {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #595f59;
  font-size: 1.2rem;
  line-height: 25px;
  font-weight: 400;
  white-space: normal;
}

.text-size-medium-5.homeeee._2 {
  margin-top: 102px;
  font-size: 1.2rem;
  line-height: 25px;
}

.text-size-medium-5.homeeee._2._5 {
  color: #fff;
}

.body-5 {
  background-color: #e6e8e6;
}

.div-block-10 {
  padding: 19px 41px;
  background-color: #004cff;
  text-align: center;
}

.text-block-19 {
  color: #fff;
}

@media screen and (min-width: 1920px) {
  .navbar1_component.home.donate {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: block;
    margin-top: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .page-padding {
    border-style: none;
  }

  .margin-bottom.margin-xxlarge.faqq {
    margin-top: 0rem;
    padding-right: 0%;
    padding-left: 0%;
  }

  .margin-bottom.margin-xxlarge.home {
    padding-right: 17%;
    padding-left: 17%;
  }

  .margin-bottom.margin-xxlarge.presss {
    margin-top: 0rem;
    margin-bottom: 36px;
    padding-right: 0%;
    padding-left: 0%;
  }

  .margin-bottom.margin-xxlarge {
    margin: 5.8rem auto 38px;
    padding-right: 0%;
    padding-left: 0%;
  }

  .padding-vertical.padding-xhuge {
    overflow: visible;
  }

  .section-cta26.midsect {
    margin-top: -13px;
  }

  .layout219_component {
    margin-top: 67px;
    margin-bottom: 56px;
  }

  .section-layout210 {
    overflow: visible;
  }

  .section-layout210.lastsect {
    margin-top: -162px;
  }

  .layout210_component.home {
    -ms-grid-columns: 3fr 2.5fr;
    grid-template-columns: 3fr 2.5fr;
  }

  .section-layout192 {
    overflow: visible;
  }

  .footer2_component {
    background-color: #7a5555;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .page-padding-2 {
    padding-top: 230px;
  }

  .page-padding-2.priciing.pricingdiv {
    margin-top: 36px;
    padding-right: 5%;
    padding-left: 5%;
  }

  .page-padding-2.faq {
    padding-top: 0px;
  }

  .page-padding-2.pricing.procingfaqdiv {
    margin-top: 865px;
    padding-top: 200px;
  }

  .page-padding-2.sales {
    margin-top: -194px;
    padding-top: 0px;
  }

  .page-padding-2.sales.saless {
    margin-top: -364px;
    padding-top: 125px;
  }

  .page-padding-2.donate {
    margin-top: 0px;
    padding-top: 0px;
  }

  .button-2.max-width-full.red.home.splashpage.blue {
    border-style: solid;
    border-width: 2px;
    border-color: #b8b6cc;
    background-color: #1e1d27;
  }

  .image {
    left: 1302px;
  }

  .image-4 {
    left: 117px;
    top: 560.594px;
  }

  .image-5 {
    left: -74px;
    top: -117.406px;
  }

  .image-6 {
    left: 1412.5px;
    top: 476.594px;
  }

  .section-pricing12.divv {
    margin-top: 96px;
  }

  .section-contact12 {
    margin-top: 308px;
    margin-bottom: 233px;
    padding-top: 0px;
  }

  .section-contact12.form.divvv {
    margin-top: 204px;
  }

  .section-contact12.terms {
    margin-top: 155px;
  }

  .section-contact12.form {
    display: block;
    padding-top: 125px;
  }

  .form-input-2 {
    border-radius: 15px;
  }

  .layout31_item-list {
    grid-auto-flow: row;
  }

  .background-video-3 {
    height: 999px;
  }

  .image-12 {
    left: 40%;
    top: 0%;
    max-width: 20%;
  }

  .page-padding-3 {
    overflow: visible;
  }

  .div-block-3 {
    margin-top: 813px;
  }

  .image-16 {
    max-width: 50%;
  }

  .image-20 {
    margin-bottom: 81px;
  }

  .image-21 {
    max-width: 70%;
    margin-top: 153px;
    margin-left: 155px;
    padding-bottom: 21px;
  }

  .image-22.blue {
    margin-top: -322px;
    margin-left: 80px;
  }

  .page-padding-4 {
    margin-top: -197px;
    border-style: none;
  }

  .page-padding-4.first {
    overflow: visible;
    margin-top: -280px;
    margin-bottom: -1022px;
  }

  .container-large-2 {
    margin-bottom: 82px;
    padding-top: 0px;
  }

  .page-padding-5 {
    border-style: none;
  }

  .page-padding-5.homeee {
    overflow: visible;
    background-image: url('../images/dotted.png');
    background-position: 50% 70%;
    background-size: auto;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .section-layout210-2 {
    margin-top: -162px;
    margin-bottom: -68px;
  }

  .container-large-3 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-testimonial7-2 {
    padding-top: 91px;
  }

  .section-testimonial11 {
    overflow: hidden;
    margin-bottom: 81px;
    padding-bottom: 3px;
  }

  .image-24 {
    left: 1421px;
    top: 417px;
    margin-left: 228px;
  }

  .image-24._2 {
    left: -76px;
    top: 750px;
    margin-top: 1868px;
    margin-left: -429px;
  }

  .div-block-6 {
    padding-top: 72px;
  }

  .div-block-7 {
    padding-top: 29px;
    padding-bottom: 76px;
    background-color: #252525;
  }

  .footer2_component-2 {
    margin-top: 0px;
    padding-top: 135px;
    background-color: #7a5555;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .section-faq2-2 {
    margin-top: -71px;
  }

  .container-large-5 {
    margin-bottom: 82px;
    padding-top: 0px;
  }

  .section-layout210-3 {
    margin-top: -162px;
    margin-bottom: -68px;
  }

  .section-layout210-3.devshop {
    margin-top: -158px;
  }

  .div-block-9.devshop {
    padding-top: 243px;
  }

  .header2_content-2 {
    margin-top: 41px;
  }

  .image-26 {
    max-width: 100%;
    margin-top: 28px;
    margin-left: 6px;
    padding-bottom: 28px;
  }

  .page-padding-6 {
    border-style: none;
  }

  .page-padding-6.homeee {
    padding-bottom: 9px;
  }

  .section-header2-2.devshops {
    margin-top: -70px;
  }

  .footer2_component-3 {
    background-color: #7a5555;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .section-layout89-2.devshop {
    padding-top: 80px;
  }
}

@media screen and (max-width: 991px) {
  .navbar1_dropdwn-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-icon1_line-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    border: 5px none #000;
    border-radius: 0px;
    background-color: #000;
    color: #fff;
    font-style: normal;
    text-decoration: none;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .menu-icon1_line-bottom.home {
    background-color: #fff;
  }

  .navbar1_menu-buttons {
    margin-top: 1.5rem;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .dropdown-icon {
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    margin-top: 0rem;
    margin-right: 0px;
    margin-bottom: 0rem;
  }

  .menu-icon1_line-middle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 2px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
  }

  .menu-icon1_line-middle.home {
    background-color: #fff;
  }

  .navbar1_menu-dropdown {
    width: 100%;
    font-size: 1.125rem;
  }

  .menu-icon_line-middle-inner {
    width: 4px;
    height: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .navbar1_link {
    width: auto;
    padding: 0.75rem 0rem;
    font-size: 1.125rem;
  }

  .navbar1_dropdown-link {
    width: auto;
    padding: 0.75rem 0rem 0.75rem 5%;
  }

  .navbar1_dropdown-list {
    position: static;
    overflow: hidden;
  }

  .navbar1_dropdown-list.w--open {
    padding: 0px;
    border-style: none;
  }

  .menu-icon1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48px;
    height: 48px;
    margin-right: -0.5rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 1;
    color: #fff;
  }

  .navbar1_component.home.donate {
    margin-top: 0px;
  }

  .menu-icon1_line-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    color: #fff;
  }

  .menu-icon1_line-top.home {
    background-color: #fff;
  }

  .navbar1_menu {
    overflow: auto;
    padding: 1rem 5% 2rem;
    border-bottom: 1px solid #000;
    background-color: transparent;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .navbar1_menu.home {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e8e6), to(#e6e8e6));
    background-image: linear-gradient(180deg, #e6e8e6, #e6e8e6);
    box-shadow: 0 20px 12px -4px rgba(0, 0, 0, 0.2);
  }

  .navbar1_menu.home.dark {
    background-color: #1e1d27;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1e1d27), to(#1e1d27));
    background-image: linear-gradient(180deg, #1e1d27, #1e1d27);
  }

  .navbar1_menu-button {
    border-style: none;
    border-width: 1px;
    border-color: #df2424;
  }

  .navbar1_menu-button.w--open {
    background-color: transparent;
  }

  .navbar1_button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    margin-left: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-header2 {
    margin-bottom: -28px;
  }

  .header2_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 0rem;
    grid-row-gap: 4rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .page-padding {
    margin-bottom: 23px;
  }

  .page-padding.divvvv {
    margin-top: 98px;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-bottom.margin-small.devshops {
    margin-top: -6.9rem;
  }

  .margin-bottom.margin-large.sales {
    padding-right: 0px;
    padding-left: 0px;
  }

  .header2_image-wrapper {
    display: none;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .layout89_content-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .section-layout89.sdkweb {
    margin-top: -34px;
  }

  .layout89_component {
    grid-column-gap: 1.25rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
  }

  .section-cta26 {
    padding-top: 400px;
    padding-bottom: 238px;
  }

  .layout219_lightbox-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .heading-medium {
    font-size: 0.8rem;
  }

  .layout219_component {
    grid-column-gap: 3rem;
  }

  .section-layout210.lastsect {
    margin-top: -193px;
    margin-bottom: -83px;
  }

  .layout210_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 1rem;
    -ms-grid-columns: 1fr 1.25fr;
    grid-template-columns: 1fr 1.25fr;
  }

  .layout210_component.home.homedivs {
    display: block;
    margin-top: 46px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .layout210_component.usecasegrid {
    grid-column-gap: 1rem;
  }

  .layout192_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 0rem;
    -ms-grid-columns: 1fr 1.25fr;
    grid-template-columns: 1fr 1.25fr;
  }

  .text-weight-semibold-2.homee {
    margin-bottom: 48px;
  }

  .footer2_bottom-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer2_form-wrapper {
    -ms-grid-columns: 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .footer2_column-layout {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .footer2_top-wrapper {
    grid-row-gap: 4rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer2_socials-wrapper {
    margin-bottom: 2rem;
  }

  .page-padding-2 {
    margin-top: -150px;
    padding-top: 12px;
  }

  .page-padding-2.priciing.pricingdiv {
    padding-right: 5%;
    padding-left: 5%;
  }

  .page-padding-2.faq.divfaq {
    padding-top: 0px;
  }

  .page-padding-2.pricing.procingfaqdiv {
    margin-top: 980px;
  }

  .page-padding-2.sales {
    margin-top: -15px;
  }

  .page-padding-2.sales.saless {
    margin-top: -135px;
  }

  .page-padding-2.donate {
    margin-top: -97px;
    padding-top: 0px;
  }

  .layout194_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 3rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .button-2.max-width-full.red.pholosophy.sdkweb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 191px;
    margin-left: 191px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .button-2.max-width-full.red.pholosophy.sdkweb.centered {
    margin-right: 129px;
    margin-left: 129px;
  }

  .faq2_component {
    border-bottom-width: 0px;
  }

  .section-faq2 {
    margin-top: -6px;
  }

  .faq2_question {
    border-top-color: #d1d1d1;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .paragraph {
    margin-top: -23px;
  }

  .paragraph._4 {
    margin-top: 0px;
  }

  .heading-2 {
    padding-right: 210px;
  }

  .heading-2.centered {
    font-size: 50px;
  }

  .heading-2.centered.bigger {
    font-size: 40px;
    line-height: 40px;
  }

  .image-2 {
    display: inline-block;
  }

  .image-2.imgpay {
    margin-top: 38px;
  }

  .image-2.imgmusic {
    margin-top: 59px;
  }

  .image-2.imgsocial {
    margin-top: 79px;
  }

  .image-3 {
    left: 0px;
    top: 2401px;
    z-index: 0;
  }

  .image-4 {
    display: none;
  }

  .image-5 {
    left: 339px;
    top: -70.406px;
    z-index: 1;
    display: none;
    width: 600px;
  }

  .image-7 {
    left: 423.5px;
    top: 756.594px;
  }

  .heading-7.pricing._2 {
    margin-top: 5px;
  }

  .heading-7.donate {
    margin-top: 16px;
  }

  .heading-7.sales {
    padding-right: 0px;
  }

  .image-8 {
    max-width: 100%;
    margin-top: -61px;
    margin-bottom: 59px;
    margin-left: 85px;
    padding-right: 0px;
  }

  .image-8.blue {
    display: none;
  }

  .image-10 {
    display: none;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .text-size-medium-3.pricing {
    margin-right: 0px;
    margin-bottom: -14px;
  }

  .text-size-medium-3.sales.terms {
    margin-right: 0px;
    margin-left: 0px;
  }

  .heading-medium-2 {
    font-size: 1.75rem;
  }

  .heading-xxlarge {
    font-size: 3.25rem;
  }

  .contact12_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 0rem;
    -ms-grid-columns: 1fr minmax(0px, 0.25fr);
    grid-template-columns: 1fr minmax(0px, 0.25fr);
  }

  .contact12_form {
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }

  .section-contact12 {
    margin-top: 0px;
    padding-top: 84px;
  }

  .section-contact12.form.divvv {
    padding-top: 168px;
  }

  .section-contact12.terms {
    margin-top: -34px;
    padding-top: 0px;
  }

  .section-contact12.form {
    padding-top: 170px;
  }

  .layout31_item-list {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 2.75fr;
    grid-template-columns: 1fr 2.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .layout31_component {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 3rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .image-11 {
    display: none;
  }

  .div-block-2.divvv {
    padding-top: 86px;
  }

  .image-12 {
    left: 34%;
    top: -1%;
    z-index: 0;
    max-width: 30%;
  }

  .image-14 {
    display: none;
    overflow: hidden;
  }

  .layout128_component {
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
  }

  .button-3.max-width-full.red {
    margin-right: 316px;
  }

  .grid-3 {
    -ms-grid-columns: minmax(124px, 0.75fr) minmax(282px, 2fr) minmax(196px, 0.25fr);
    grid-template-columns: minmax(124px, 0.75fr) minmax(282px, 2fr) minmax(196px, 0.25fr);
  }

  .div-block-3 {
    margin-top: 56px;
  }

  .image-16 {
    max-width: 70%;
  }

  .columns-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .columns-5 {
    margin-bottom: 64px;
  }

  .navbar1_menu-2 {
    overflow: auto;
    padding: 1rem 5% 2rem;
    border-bottom: 1px solid #000;
    background-color: transparent;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .navbar1_menu-2.home {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 0px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e8e6), to(#e6e8e6));
    background-image: linear-gradient(180deg, #e6e8e6, #e6e8e6);
    box-shadow: 0 20px 12px -4px rgba(0, 0, 0, 0.2);
  }

  .navbar1_link-2 {
    width: auto;
    padding: 0.75rem 0rem;
    font-size: 1.125rem;
  }

  .image-20 {
    max-width: 100%;
    margin-top: -61px;
    margin-bottom: 59px;
    margin-left: 85px;
    padding-right: 0px;
  }

  .image-21 {
    display: none;
  }

  .image-22 {
    max-width: 100%;
    margin-top: -61px;
    margin-bottom: 59px;
    margin-left: 85px;
    padding-right: 231px;
  }

  .image-22.blue {
    display: none;
  }

  .page-padding-4 {
    margin-bottom: 23px;
  }

  .page-padding-4.first {
    margin-top: 99px;
    margin-bottom: -113px;
  }

  .layout89_component-2 {
    grid-column-gap: 1.25rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
  }

  .heading-9 {
    padding-right: 210px;
  }

  .heading-9.cases {
    font-size: 29px;
    line-height: 31px;
    letter-spacing: 0px;
  }

  .container-large-2 {
    padding-right: 5%;
    padding-left: 5%;
  }

  .page-padding-5 {
    margin-bottom: 23px;
    padding-right: 0%;
    padding-left: 0%;
  }

  .page-padding-5.homeee {
    margin-top: 40px;
    background-position: 50% 90%;
    background-size: auto;
  }

  .image-23.align {
    margin-top: 22px;
  }

  .text-weight-semibold-4.homee.devshops {
    text-align: left;
  }

  .section-layout210-2 {
    margin-top: -64px;
  }

  .layout210_component-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-flow: row;
    grid-column-gap: 0rem;
    -ms-grid-columns: 1fr 1.25fr;
    grid-template-columns: 1fr 1.25fr;
  }

  .heading-xsmall-2 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #595f59;
    font-weight: 500;
  }

  .testimonial11_component {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .testimonial11_mask {
    width: 100%;
  }

  .image-24 {
    left: 476px;
    top: -386px;
    display: none;
    margin-left: 90px;
  }

  .image-24._2 {
    left: -325px;
    top: 82px;
    margin-top: 1934px;
    margin-left: -124px;
  }

  .testimonial11_slide {
    margin-right: 2.5rem;
    padding-right: 0rem;
  }

  .section-faq2-2 {
    margin-top: -6px;
  }

  .layout89_component-3 {
    grid-column-gap: 1.25rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
  }

  .layout31_component-2 {
    min-height: auto;
    grid-auto-flow: row;
    grid-column-gap: 3rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .link-32 {
    color: #f95151;
  }

  .container-large-5 {
    padding-right: 5%;
    padding-left: 5%;
  }

  .layout89_component-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column-gap: 1.25rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
  }

  .section-layout210-3 {
    margin-top: -64px;
  }

  .div-block-9.devshop {
    padding-top: 102px;
  }

  .footer2_bottom-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .image-26 {
    display: none;
  }

  .page-padding-6 {
    margin-bottom: 23px;
  }

  .section-layout210-4.s2.devshop {
    margin-top: -19px;
  }

  .section-header2-2 {
    margin-top: 0px;
    margin-bottom: -28px;
    padding-top: 87px;
  }

  .section-header2-2.devshops {
    margin-top: 3px;
    margin-bottom: 57px;
  }
}

@media screen and (max-width: 767px) {
  .menu-icon1_line-bottom {
    background-color: #000;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .menu-icon1_line-middle {
    background-color: #000;
  }

  .navbar1_container {
    color: #e6e8e6;
  }

  .navbar1_container.home {
    background-color: rgba(30, 29, 39, 0);
  }

  .menu-icon1 {
    border-style: none;
    border-width: 1px;
    border-color: #000;
    background-color: hsla(0, 0%, 39.3%, 0);
  }

  .navbar1_component {
    height: 4rem;
  }

  .navbar1_component.home {
    background-color: #1e1d27;
  }

  .navbar1_component.home.donate {
    margin-top: 0px;
    background-color: rgba(30, 29, 39, 0);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e8e6), to(#e6e8e6));
    background-image: linear-gradient(180deg, #e6e8e6, #e6e8e6);
  }

  .menu-icon1_line-top {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    border-style: none;
    border-width: 0px;
    border-color: #fff;
    border-radius: 0px;
    background-color: #000;
    outline-color: #555;
    outline-offset: 0px;
    outline-style: none;
    outline-width: 3px;
    color: #fff;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .navbar1_menu.home {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e8e6), to(#e6e8e6));
    background-image: linear-gradient(180deg, #e6e8e6, #e6e8e6);
    box-shadow: 0 20px 11px -3px rgba(0, 0, 0, 0.22);
  }

  .navbar1_menu.home.dark {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1e1d27), to(#1e1d27));
    background-image: linear-gradient(180deg, #1e1d27, #1e1d27);
  }

  .header2_component {
    grid-row-gap: 3rem;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-bottom.margin-small.devshops {
    margin-top: -2.5rem;
  }

  .margin-bottom.margin-large.sales {
    padding-right: 0px;
    padding-left: 30px;
  }

  .margin-bottom.margin-xsmall.is-small-mobile {
    margin-bottom: 1rem;
  }

  .text-size-medium {
    font-size: 1rem;
  }

  .text-size-medium.homeeee._2 {
    margin-top: 39px;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .layout89_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-cta26 {
    padding-bottom: 189px;
  }

  .heading-medium {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .layout219_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-layout210.lastsect {
    margin-top: -147px;
    margin-bottom: -83px;
  }

  .layout210_image-wrapper {
    margin-top: -35px;
  }

  .layout210_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout210_component.home.homedivs {
    display: block;
    padding-right: 20px;
    padding-left: 20px;
  }

  .layout210_component.usecasegrid {
    justify-items: center;
  }

  .layout192_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer2_legal-wrapper {
    grid-auto-flow: row;
    grid-column-gap: 0rem;
    grid-row-gap: 1rem;
  }

  .footer2_credit-text {
    margin-top: 1.5rem;
  }

  .footer2_bottom-wrapper {
    padding-bottom: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .footer2_top-wrapper {
    grid-row-gap: 3rem;
  }

  .page-padding-2 {
    padding-top: 71px;
  }

  .page-padding-2.faq.divfaq {
    margin-top: 10px;
    padding-top: 0px;
  }

  .page-padding-2.sales {
    margin-top: -148px;
  }

  .page-padding-2.sales.saless {
    margin-top: -214px;
  }

  .page-padding-2.donate {
    margin-top: -90px;
    padding-top: 0px;
  }

  .layout194_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading {
    font-size: 40px;
    line-height: 42px;
  }

  .navbar1_component-2 {
    height: 4rem;
  }

  .button-2.max-width-full.red.pholosophy.sdkweb {
    margin-right: 103px;
    margin-left: 103px;
  }

  .faq2_question {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .text-size-medium-2 {
    font-size: 1rem;
  }

  .text-size-medium-2.faq2 {
    margin-top: 16px;
  }

  .faq2_icon {
    width: 1.75rem;
  }

  .heading-2 {
    padding-right: 84px;
  }

  .heading-2.centered {
    line-height: 60px;
  }

  .heading-3 {
    font-size: 60px;
    line-height: 60px;
  }

  .image-2 {
    text-align: left;
  }

  .image-2.imgpay {
    margin-top: 0px;
  }

  .image-2.imgmusic {
    margin-top: 0px;
  }

  .image-2.imgsocial {
    margin-top: 0px;
  }

  .image-4 {
    left: -259px;
    top: 88.594px;
    right: -277px;
  }

  .image-5 {
    left: 222px;
    top: -63.406px;
  }

  .image-7 {
    left: 111.5px;
    top: 801.594px;
  }

  .heading-7 {
    margin-bottom: 0px;
  }

  .heading-7.pricing {
    margin-top: -19px;
  }

  .heading-7.pricing._2 {
    margin-top: 12px;
  }

  .image-8 {
    margin-top: -42px;
    margin-bottom: 50px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .columns {
    display: block;
  }

  .heading-xsmall {
    font-size: 1.125rem;
  }

  .pricing12_plan {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .text-size-medium-3 {
    font-size: 1rem;
  }

  .text-size-medium-3.pricing {
    margin-top: 17px;
  }

  .text-size-medium-3.sales.thankstitle {
    margin-top: 17px;
  }

  .pricing12_component {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-medium-2 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .heading-xxlarge {
    font-size: 2.5rem;
  }

  .contact12_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .form-field-2col.is-mobile-1col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-contact12 {
    margin-bottom: 0px;
    padding-top: 97px;
  }

  .section-contact12.terms {
    margin-top: 51px;
  }

  .layout31_item-list {
    -ms-grid-columns: 1.25fr 2.75fr;
    grid-template-columns: 1.25fr 2.75fr;
  }

  .layout31_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-small {
    font-size: 1.25rem;
  }

  .div-block {
    margin-bottom: 115px;
  }

  .background-video {
    margin-bottom: 399px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .div-block-2 {
    margin-top: auto;
  }

  .div-block-2.divvv {
    padding-top: 85px;
  }

  .image-12 {
    left: 31%;
    top: -1%;
    max-width: 40%;
  }

  .faq_answernew {
    margin-top: 0px;
    color: #646464;
  }

  .section-layout128 {
    margin-top: 45px;
  }

  .layout128_component {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button-3.max-width-full.red {
    margin-right: 246px;
  }

  .button-3.max-width-full.red.home.devshops {
    margin-right: 180px;
  }

  .grid-3 {
    -ms-grid-columns: minmax(81px, 2fr) minmax(205px, 2fr) minmax(172px, 0.25fr);
    grid-template-columns: minmax(81px, 2fr) minmax(205px, 2fr) minmax(172px, 0.25fr);
  }

  .image-16 {
    max-width: 80%;
  }

  .image-18 {
    display: inline-block;
    text-align: center;
  }

  .columns-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-5 {
    margin-top: -22px;
  }

  .navbar1_menu-2.home {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e8e6), to(#e6e8e6));
    background-image: linear-gradient(180deg, #e6e8e6, #e6e8e6);
    box-shadow: 0 20px 11px -3px rgba(0, 0, 0, 0.22);
  }

  .image-20 {
    margin-top: -42px;
    margin-bottom: 67px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .text-size-medium-4 {
    font-size: 1rem;
  }

  .image-22 {
    margin-top: -42px;
    margin-bottom: 50px;
    padding-top: 0px;
    padding-right: 88px;
  }

  .heading-8 {
    font-size: 40px;
    line-height: 42px;
  }

  .layout89_component-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-9 {
    padding-right: 84px;
  }

  .image-23 {
    text-align: left;
  }

  .layout210_component-2 {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .testimonial7_component {
    padding-bottom: 3rem;
  }

  .testimonial7_slide {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .heading-xsmall-2 {
    font-size: 1.125rem;
  }

  .testimonial11_client {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .testimonial11_client-image-wrapper {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .testimonial11_component {
    padding-bottom: 3rem;
  }

  .testimonial11_arrow.left {
    right: 3.5rem;
  }

  .image-24 {
    display: none;
    overflow: hidden;
  }

  .image-24._2 {
    display: none;
    overflow: hidden;
  }

  .testimonial11_slide {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .footer2_credit-text-2 {
    margin-top: 1.5rem;
  }

  .container-large-4 {
    display: block;
  }

  .layout89_component-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-layout31-2 {
    display: block;
  }

  .layout31_component-2 {
    grid-row-gap: 3rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .image-25 {
    display: none;
  }

  .layout89_component-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .div-block-9.devshop {
    padding-top: 87px;
  }

  .footer2_bottom-wrapper-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .column-10 {
    padding-bottom: 21px;
  }

  .section-layout210-4.s2.devshop {
    margin-top: -40px;
  }

  .section-header2-2 {
    padding-top: 113px;
  }

  .bold-text-7 {
    font-size: 35px;
    line-height: 10px;
  }

  .image-27 {
    padding-bottom: 21px;
    text-align: left;
  }

  .section-layout89-2 {
    padding-top: 82px;
  }

  .text-size-medium-5 {
    font-size: 1rem;
  }

  .text-size-medium-5.homeeee._2 {
    margin-top: 111px;
  }

  .text-size-medium-5.homeeee._2._3 {
    margin-top: 30px;
  }

  .div-block-10 {
    padding-right: 27px;
    padding-left: 27px;
  }
}

@media screen and (max-width: 479px) {
  .header2_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .signup-form-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 0.75rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .page-padding {
    margin-top: 113px;
  }

  .page-padding.homeee {
    margin-top: -16px;
  }

  .form {
    margin-top: 20px;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .text-size-medium {
    font-size: 0.9rem;
  }

  .text-size-medium.hero {
    font-size: 0.9rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-vertical.padding-xhuge.faqs {
    padding-top: 74px;
  }

  .padding-vertical.padding-xhuge {
    padding-top: 0px;
  }

  .section-layout89 {
    margin-top: 56px;
  }

  .layout89_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-cta26 {
    padding-top: 351px;
    padding-bottom: 98px;
  }

  .section-layout219 {
    margin-top: -113px;
  }

  .section-layout219.caseshero {
    padding-bottom: 0px;
  }

  .section-layout219.caseshero.usecasess {
    margin-top: -79px;
    padding-bottom: 0px;
  }

  .layout219_component {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-layout210.lastsect {
    margin-top: -111px;
    margin-bottom: -191px;
  }

  .layout210_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout192_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer2_form-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 12px;
    -ms-grid-rows: auto 12px auto 12px auto;
    grid-template-areas: "."
      "."
      "Area";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer2_column-layout {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .footer2_top-wrapper {
    grid-row-gap: 48px;
  }

  .page-padding-2 {
    padding-top: 54px;
  }

  .page-padding-2.faq.divfaq {
    padding-top: 0px;
  }

  .page-padding-2.pricing.procingfaqdiv {
    margin-top: 1560px;
  }

  .page-padding-2.sales {
    margin-top: 0px;
    padding-top: 0px;
  }

  .page-padding-2.sales.saless {
    margin-top: -80px;
    padding-left: 0%;
  }

  .page-padding-2.donate {
    padding-top: 0px;
  }

  .layout194_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading {
    font-size: 40px;
    line-height: 40px;
  }

  .button-2.max-width-full.red.pholosophy.sdkweb {
    margin-right: 9px;
    margin-left: 9px;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .heading-2 {
    padding-right: 0px;
  }

  .heading-3 {
    font-size: 35px;
    line-height: 35px;
  }

  .image-4 {
    left: -139px;
    top: 115.594px;
  }

  .image-5 {
    left: 120px;
    top: -37.406px;
    width: 800px;
  }

  .image-7 {
    left: 54.5px;
    top: 784.594px;
  }

  .image-8 {
    margin-top: -74px;
    padding-right: 0px;
    padding-bottom: 36px;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .text-size-medium-3.sales {
    margin-top: 24px;
  }

  .text-size-medium-3.sales.terms {
    padding-right: 24px;
    padding-left: 24px;
  }

  .pricing12_component {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .section-pricing12 {
    margin-bottom: 39px;
  }

  .contact12_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .contact12_contact-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-contact12 {
    margin-top: 112px;
    margin-bottom: -40px;
    padding-top: 0px;
  }

  .section-contact12.form.divvv {
    margin-top: -67px;
    padding-top: 185px;
  }

  .section-contact12.form {
    padding-top: 19px;
  }

  .layout31_item-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout31_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .background-video {
    display: block;
    margin-bottom: 821px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .background-video-3 {
    height: 1540px;
  }

  .image-12 {
    left: 16%;
    top: 1%;
    max-width: 70%;
  }

  .faq_answernew {
    margin-top: 0px;
    margin-bottom: 42px;
    padding-bottom: 0px;
  }

  .section-layout128 {
    margin-top: 104px;
  }

  .layout128_component {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button-3.max-width-full.red {
    margin-right: 0px;
  }

  .button-3.max-width-full.red.home.devshops {
    margin-right: 6px;
  }

  .grid-3 {
    -ms-grid-columns: minmax(81px, 2fr) minmax(86px, 2fr) minmax(91px, 0.25fr);
    grid-template-columns: minmax(81px, 2fr) minmax(86px, 2fr) minmax(91px, 0.25fr);
  }

  .text-block-17.small {
    font-size: 12px;
    line-height: 20px;
  }

  .image-16 {
    max-width: 100%;
  }

  .column-5 {
    margin-top: -28px;
    padding-top: 0px;
  }

  .image-19 {
    margin-top: 14px;
    padding-top: 0px;
  }

  .image-20 {
    margin-top: -12px;
    margin-bottom: 67px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .text-size-medium-4 {
    font-size: 0.9rem;
  }

  .text-size-medium-4.hero {
    font-size: 0.9rem;
  }

  .image-22 {
    margin-top: -74px;
    padding-right: 22px;
    padding-bottom: 36px;
  }

  .heading-8 {
    font-size: 40px;
    line-height: 40px;
  }

  .page-padding-4 {
    margin-top: 113px;
  }

  .page-padding-4.first {
    margin-top: 33px;
  }

  .page-padding-4.first.hero {
    margin-bottom: -42px;
  }

  .layout89_component-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-9 {
    padding-right: 0px;
  }

  .heading-9.cases {
    font-size: 40px;
    line-height: 40px;
  }

  .container-large-2 {
    padding-top: 0px;
    padding-bottom: 41px;
  }

  .page-padding-5 {
    margin-top: 113px;
  }

  .page-padding-5.homeee {
    margin-top: -16px;
    background-position: 50% 100%;
  }

  .section-layout210-2 {
    margin-top: -30px;
  }

  .layout210_component-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-testimonial7-2 {
    padding-top: 57px;
  }

  .heading-10 {
    line-height: 40px;
  }

  .footer2_form-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout89_component-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout31_component-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout31_item-list-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .layout89_component-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-layout210-3 {
    margin-top: -30px;
  }

  .section-layout210-3.devshop {
    margin-top: -123px;
  }

  .div-block-9.devshop {
    padding-top: 45px;
  }

  .page-padding-6 {
    margin-top: 113px;
  }

  .page-padding-6.homeee {
    margin-top: -16px;
  }

  .bold-text-6 {
    font-size: 35px;
    line-height: 20px;
  }

  .bold-text-6.devshops {
    font-size: 30px;
    line-height: 20px;
  }

  .section-header2-2 {
    margin-top: 0px;
    padding-top: 65px;
  }

  .section-header2-2.devshops {
    margin-top: -7px;
  }

  .section-layout89-2 {
    margin-top: 56px;
  }

  .bold-text-8 {
    font-size: 40px;
  }

  .text-size-medium-5 {
    font-size: 0.9rem;
  }

  .text-size-medium-5.hero {
    font-size: 0.9rem;
  }

  .text-block-19 {
    font-size: 12px;
    line-height: 16px;
  }
}

#w-node-_1cc70cec-b878-df27-ac99-db8ae26c61cf-0629d905 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_73a44653-3a27-1188-d24c-cb899907e742-0629d905 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ed968b55-f36c-1006-74f9-414addf3d751-0629d905 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_165620b3-cc70-e20a-3713-11c32790b195-0629d905 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9e9163ae-6171-affc-0eee-a9fbf46e7697-0629d905 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-d8631139-18fe-6bea-fcd1-d174234d1022-0629d905 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_102bd7ef-a531-c8d4-ea60-ecc4c94c26db-0629d905 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-fa8db401-d06c-0f96-b88f-de5e135f29d5-6c29d907 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-f1b29376-2a0c-2b66-9704-9a4e1e17b672-cb29d909 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf837d-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf8382-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf8387-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_7081ba9a-e616-cd1e-f341-e9dba2dc1313-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf83a0-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf83a5-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf83aa-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d80bc7f0-d53f-645f-e033-954f84cf83af-2d29d90b {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_8bab66fa-ab2c-f81f-d103-44519d294509-2d29d90b {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_4f62f136-2465-206c-9653-f2113c9b6da8-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4d7926c5-6a95-320f-ae89-be17c1cc2d02-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ce6f149f-7eea-ea80-243f-2c577a759c6c-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-eff48501-1dd4-caa0-976c-95b45a922e30-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ca01539f-7e96-179d-3518-5f267355034e-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9f6fb49a-9870-f4f2-6cdf-5e1a44a6f13f-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_3e645b2c-68af-2b4e-42f2-5176af953177-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_11ba92c6-aa8c-5918-974e-c46c9313ba89-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_104b1024-a67f-cf7d-7c48-7a8c0b75c1f2-7a29d90e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f84e4d77-7145-7a48-fbaa-5849830768e6-7a29d90e {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_8e0c4a9e-4b62-3473-afcb-b0cc5add17fe-0c29d90f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_842e7f72-ff7b-8264-3dc6-9207f5a2f197-0c29d90f {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_25215ee6-c495-2b04-1e27-2f8ef68ad5e7-2a29d910 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_6df339fc-c138-9b6c-6432-c89da432066e-ef29d911 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_8124d21d-cdd5-53f0-cd81-7f754acb3499-3d29d912 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-ac657567-aaa5-31ef-2180-f9c42d796599-3d29d912 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-deb54b44-abde-0dc8-949e-4db2025e0992-3d29d912 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-fa9063f0-d7f7-b773-84fc-168adad66de7-3d29d912 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_1600eb28-3431-35fa-7367-b57a74cc00a5-3d29d912 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_4ef18d40-b284-acbc-8d93-54a32f4479ed-7529f769 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4ef18d40-b284-acbc-8d93-54a32f4479f6-7529f769 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4ef18d40-b284-acbc-8d93-54a32f4479f8-7529f769 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_54818b79-ec5b-eca7-0a6a-b36af6b68462-7529f769 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-d9b7f96e-82be-59a8-611a-3b68f56e3a94-1f736420 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2632f188-55e3-86db-60af-953e89ea5a29-1f736420 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_68f0f4eb-ec21-efad-0377-10de0a4e8183-35da6572 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81ce-35da6572 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81e4-35da6572 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81fa-35da6572 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_84a718b4-909f-5822-8962-072ee2fd0400-35da6572 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

@media screen and (min-width: 1920px) {
  #w-node-_9e9163ae-6171-affc-0eee-a9fbf46e7697-0629d905 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-d8631139-18fe-6bea-fcd1-d174234d1022-0629d905 {
    -ms-grid-column-align: end;
    justify-self: end;
  }
}

@media screen and (max-width: 991px) {
  #w-node-d698217b-4a7d-a883-1514-d01b5291df62-0629d905 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-fa8db401-d06c-0f96-b88f-de5e135f29b9-6c29d907 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f1b29376-2a0c-2b66-9704-9a4e1e17b656-cb29d909 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_8bab66fa-ab2c-f81f-d103-44519d2944ed-2d29d90b {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f84e4d77-7145-7a48-fbaa-5849830768ca-7a29d90e {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_842e7f72-ff7b-8264-3dc6-9207f5a2f17b-0c29d90f {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_25215ee6-c495-2b04-1e27-2f8ef68ad5cb-2a29d910 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6df339fc-c138-9b6c-6432-c89da4320652-ef29d911 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_1600eb28-3431-35fa-7367-b57a74cc0089-3d29d912 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d10f-7529f769 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_2632f188-55e3-86db-60af-953e89ea5a0f-1f736420 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_84a718b4-909f-5822-8962-072ee2fd03e4-35da6572 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
}

@media screen and (max-width: 767px) {
  #w-node-ed968b55-f36c-1006-74f9-414addf3d73e-0629d905 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-ed968b55-f36c-1006-74f9-414addf3d751-0629d905 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_9e9163ae-6171-affc-0eee-a9fbf46e7699-0629d905 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-d8631139-18fe-6bea-fcd1-d174234d1024-0629d905 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-d698217b-4a7d-a883-1514-d01b5291df62-0629d905 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-d698217b-4a7d-a883-1514-d01b5291dfa7-0629d905 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-fa8db401-d06c-0f96-b88f-de5e135f29b9-6c29d907 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-fa8db401-d06c-0f96-b88f-de5e135f29e9-6c29d907 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-f1b29376-2a0c-2b66-9704-9a4e1e17b656-cb29d909 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f1b29376-2a0c-2b66-9704-9a4e1e17b686-cb29d909 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_8bab66fa-ab2c-f81f-d103-44519d2944ed-2d29d90b {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_8bab66fa-ab2c-f81f-d103-44519d29451d-2d29d90b {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-f84e4d77-7145-7a48-fbaa-5849830768ca-7a29d90e {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-f84e4d77-7145-7a48-fbaa-5849830768fa-7a29d90e {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_842e7f72-ff7b-8264-3dc6-9207f5a2f17b-0c29d90f {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_842e7f72-ff7b-8264-3dc6-9207f5a2f1ab-0c29d90f {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_25215ee6-c495-2b04-1e27-2f8ef68ad5cb-2a29d910 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_25215ee6-c495-2b04-1e27-2f8ef68ad5fb-2a29d910 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_6df339fc-c138-9b6c-6432-c89da4320652-ef29d911 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6df339fc-c138-9b6c-6432-c89da4320682-ef29d911 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_8124d21d-cdd5-53f0-cd81-7f754acb3499-3d29d912 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_8124d21d-cdd5-53f0-cd81-7f754acb349b-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-ac657567-aaa5-31ef-2180-f9c42d796599-3d29d912 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ac657567-aaa5-31ef-2180-f9c42d79659b-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-deb54b44-abde-0dc8-949e-4db2025e0992-3d29d912 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-deb54b44-abde-0dc8-949e-4db2025e0994-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-fa9063f0-d7f7-b773-84fc-168adad66de7-3d29d912 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-fa9063f0-d7f7-b773-84fc-168adad66de9-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_907657ef-14a3-a0e5-34c9-4e27862a5f26-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_310625d7-f222-76c5-bfbd-5d7038bca509-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_39c853c2-90c9-1308-5438-ba199b86157c-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_371eab64-a40a-575f-03b1-8f4f903a93ae-3d29d912 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_1600eb28-3431-35fa-7367-b57a74cc0089-3d29d912 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_1600eb28-3431-35fa-7367-b57a74cc00b9-3d29d912 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_9f872043-ab9a-a784-79da-7ed2b5648196-7529f769 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d10f-7529f769 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d13c-7529f769 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_2632f188-55e3-86db-60af-953e89ea5a0f-1f736420 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_2632f188-55e3-86db-60af-953e89ea5a3d-1f736420 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-_68f0f4eb-ec21-efad-0377-10de0a4e8198-35da6572 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81ae-35da6572 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81bd-35da6572 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81d5-35da6572 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81eb-35da6572 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_84a718b4-909f-5822-8962-072ee2fd03e4-35da6572 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_84a718b4-909f-5822-8962-072ee2fd0414-35da6572 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d698217b-4a7d-a883-1514-d01b5291df62-0629d905 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-fa8db401-d06c-0f96-b88f-de5e135f29b9-6c29d907 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f1b29376-2a0c-2b66-9704-9a4e1e17b656-cb29d909 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_8bab66fa-ab2c-f81f-d103-44519d2944ed-2d29d90b {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f84e4d77-7145-7a48-fbaa-5849830768ca-7a29d90e {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_842e7f72-ff7b-8264-3dc6-9207f5a2f17b-0c29d90f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_25215ee6-c495-2b04-1e27-2f8ef68ad5cb-2a29d910 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6df339fc-c138-9b6c-6432-c89da4320652-ef29d911 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_1600eb28-3431-35fa-7367-b57a74cc0089-3d29d912 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d10f-7529f769 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_2632f188-55e3-86db-60af-953e89ea5a0f-1f736420 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_84a718b4-909f-5822-8962-072ee2fd03e4-35da6572 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}

.menu-icon1_line-top,
.menu-icon1_line-middle,
.menu-icon1_line-bottom,
.responsive-navbar {
  transition: all 0.4s ease;
}

.active-menu .menu-icon1_line-top {
  transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.active-menu .menu-icon1_line-middle {
  width: 0px;
  height: 2px;
}

.active-menu .menu-icon1_line-bottom {
  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.hidde-menu {
  transform: translateY(-1500px);
}

@media only screen and (min-width: 990px) {
  .responsive-navbar {
    display: none !important;
  }
}

@media only screen and (max-width: 990px) {
  .responsive-menu {
    display: none !important;
  }

  .display-md {
    display: block;
  }
}

ul.slider.animated {
  padding-bottom: 60px;
}

li.dot {
  margin: 0 4px !important;
  background-color: rgba(34, 34, 34, 0.4) !important;
}

li.dot.selected {
  background-color: #222 !important;
}

.carousel-button-arrow {
  background-color: transparent;
}

.carousel-button-arrow:first-child {
  margin-right: 25px;
}
